import { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Profile from "./Profile";
import Properties from "./Properties";
import Statements from "./Statements";
import { PropertyContext, PropertyProvider } from "../context/property";
import Login from "./Login";
import Loading from "../components/Loading";
import useSubmitWithToast from "../action/useSubmitWithToast";
import { findOwnerId } from "../action/actionOwner";

export const OwnerPortalEntrance = ({ match }: { match: any }) => {
  const { isLoading } = useContext(PropertyContext);

  return (
    <PropertyProvider match={match}>
      {isLoading && <Loading />}
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 bg-gray-500 transition-opacity" />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <PropertyEntrance />
          </div>
        </div>
      </div>
    </PropertyProvider>
  );
};

const PropertyEntrance = () => {
  const [email, setEmail] = useState("");
  const { property, propertyId } = useContext(PropertyContext);
  const history = useHistory();

  const { name: propertyName, avatar } = property || {};

  const [handleVerifyEmail] = useSubmitWithToast({
    func: () => {
      return findOwnerId({ propertyId, search: email });
    },
    successMessage: "A verification code is sent to your email inbox.",
    successFunc: (result: any) => {
      const accountId = result?.accountId;
      const link = `/${propertyId}/owner/${accountId}?method=email`;
      history.push(link);
    },
    errorFunc: (err: any) => {
      console.log("error", err?.message);
    },
  });

  return (
    <div>
      <div className="mt-3 text-center sm:mt-5">
        <img
          className="block h-20 rounded w-auto mb-5 mx-auto"
          src={avatar}
          alt={propertyName}
        />
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {propertyName}
        </h3>
        <hr className="my-8" />
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Owner Portal
        </h3>
        <div className="mt-10">
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Enter your email address"
          />
        </div>
        <div className="mt-5 sm:mt-6">
          <button
            onClick={handleVerifyEmail}
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

function OwnerPortalWrapper({ match }: { match: any; location: any }) {
  const propertyId = match.params.propertyId;
  const ownerId = match.params.ownerId;
  const tabId = match.params.tabId;

  const queryURL = useLocation().search;
  const query = queryString.parse(queryURL);
  const method = query?.method || "";

  return (
    <PropertyProvider match={match}>
      <OwnerPortal
        tabId={tabId}
        propertyId={propertyId}
        ownerId={ownerId}
        method={method}
      />
    </PropertyProvider>
  );
}

const OwnerPortal = ({
  tabId,
  propertyId,
  ownerId,
  method,
}: {
  tabId: string;
  propertyId: string;
  ownerId: string;
  method?: any;
}) => {
  const { isLoggedIn } = useContext(PropertyContext);

  return (
    <>
      {isLoggedIn === null ? (
        <Loading />
      ) : isLoggedIn === true ? (
        <div className="min-h-screen bg-white">
          <Owner tabId={tabId} propertyId={propertyId} ownerId={ownerId} />
        </div>
      ) : isLoggedIn === false ? (
        <Login propertyId={propertyId} ownerId={ownerId} method={method} />
      ) : (
        <Loading />
      )}
    </>
  );
};

const Owner = ({
  tabId,
  propertyId,
  ownerId,
}: {
  tabId: string;
  propertyId: string;
  ownerId: string;
}) => {
  const { property, account, isLoading } = useContext(PropertyContext);

  const { name: propertyName } = property || {};

  const { portalAvailability, portalStatements } = account || {};

  const navigation = [
    { name: "Profile", href: "profile", current: true },
    !!portalAvailability && {
      name: "Properties",
      href: "properties",
      current: false,
    },
    !!portalStatements && {
      name: "Statements",
      href: "statements",
      current: false,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{`Owner Portal - ${propertyName}`}</title>
      </Helmet>

      {isLoading && <Loading />}

      <Header
        tabId={tabId}
        url={`/${propertyId}/owner/${ownerId}/`}
        navigation={navigation}
        account={account}
        property={property}
      />
      <div className="py-10 min-h-screen bg-gray-100">
        {tabId === "properties" ? (
          <Properties
            account={account}
            propertyId={propertyId}
            ownerId={ownerId}
          />
        ) : tabId === "statements" ? (
          <Statements propertyId={propertyId} ownerId={ownerId} />
        ) : tabId === "profile" ? (
          <Profile
            propertyId={propertyId}
            ownerId={ownerId}
            account={account}
          />
        ) : (
          <Profile
            propertyId={propertyId}
            ownerId={ownerId}
            account={account}
          />
        )}
      </div>
      {/* <Modal open={open} setOpen={setOpen}>
          <ModalHeader onClose={() => setOpen(false)}>Test</ModalHeader>
          <ModalBody>Test</ModalBody>
          <ModalFooter>
            <button>Cancel</button>
          </ModalFooter>
        </Modal> */}
      <Footer property={property} />
    </>
  );
};

export default OwnerPortalWrapper;
